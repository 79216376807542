<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-label class="fw-600 ml-3">{{ labelPayment }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <div class="mt-5 d-flex align-center justify-center flex-column">
      <ion-icon class="f-icon" :icon="alertCircleOutline"></ion-icon>
      <ion-label class="fs-4 fw-600 mt-2 mb-2 f-orange"> {{ label }}</ion-label>
    </div>
    <ion-content>
      <ion-grid class="mx-2">
        <ion-row v-for="(item, i) in dataList" class="mb-2" :key="i">
          <ion-col>
            <ion-label class="fw-700 text-gray-700">{{ item.label }}</ion-label>
            <ion-row class="mt-2" v-for="(invoice, i) in item.invoices" :key="i">
              <ion-col>
                <ion-label class="text-gray-700">{{ invoice.label }}</ion-label>
              </ion-col>
              <ion-col size="auto" push="0.6">
                <ion-label class="fw-600 text-gray-700">{{ invoice.value }}</ion-label>
              </ion-col>
            </ion-row>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-600 text-gray-700"> {{ item.value }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row class="mb-2">
          <div class="box mb-2">
            <ion-row class="ion-align-items-center ion-justify-content-between">
              <ion-col>
                <ion-row>
                  <ion-label class="ml-2 mt-1 text-gray-700">{{ labelNotes }} </ion-label>
                </ion-row>
                <ion-row>
                  <ion-text class="ml-2 mt-2 mb-2 text-gray-700">{{ notes }} </ion-text>
                </ion-row>
              </ion-col>
            </ion-row>
          </div>
        </ion-row>
        <ion-row class="mb-2">
          <ion-col>
            <ion-label class="text-gray-700">{{ appliedCreditsLabel }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-600 text-gray-700"> {{ currencySymbolData }}{{ totalBalanceUsed }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-label class="fw-600 text-gray-700">{{ 'Total' }}</ion-label>
          </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-500 f-orange"> {{ pending }}</ion-text>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col> </ion-col>
          <ion-col size="auto">
            <ion-text class="fw-700 text-gray-700"> {{ currencySymbolData }}{{ totalPaidAmount }}</ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <ion-button class="mb-2 mt-2" @click="$emit('backToInvoice')" expand="block" color="primary">
          <ion-label class="text-capitalize"> {{ labelBackInvoice }} </ion-label>
        </ion-button>
        <ion-button class="mb-4" @click="$emit('backToHome')" fill="outline" expand="block">
          <ion-label class="text-capitalize"> {{ labelBackHome }} </ion-label>
        </ion-button>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>
<script>
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import { alertCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'payment-summary',
  emits: ['backToInvoice', 'backToHome'],
  props: {
    paymentSummary: {
      type: Object,
      default: () => {}
    },
    currencySymbol: {
      type: String,
      default: ''
    },
    invoice: {
      type: Array,
      default: () => []
    },
    paymentType: {
      type: Number,
      default: 0
    }
  },
  setup(props) {
    const { t } = useI18n();
    const label = t('payment_pending');
    const labelPayment = t('payment_summary');
    const labelBackInvoice = t('back_to_invoice');
    const labelBackHome = t('back_to_home');
    const labelNotes = t('note_only');
    const labelDescTransaction = t('enter_desc_transaction');
    const notes = ref(props.paymentSummary?.notes) || '-';
    const currencySymbolData = ref(props.currencySymbol);
    const totalBalanceUsed = ref(props.paymentSummary?.totalCustomerBalanceUsed) || 0;
    const totalPaidAmount = ref(props.paymentSummary?.totalPaidAmount);
    const appliedCreditsLabel = t('applied_credits');
    const pending = t('pending');
    const labelOptions = {
      [INVOICE_PAYMENT_TYPE.BANK_TRANSFER]: t('bank_transfer'),
      [INVOICE_PAYMENT_TYPE.CHEQUE]: t('cheque'),
      [INVOICE_PAYMENT_TYPE.CASH_PAYMENT_COLLECTION]: t('cash_payment_collection'),
      [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT]: t('cash_deposit')
    };
    const labelPaymentType = labelOptions[props.paymentType];
    const invoices = props.invoice.map((invoice) => {
      return {
        label: invoice.invoiceNumber,
        value: `${props.currencySymbol}${invoice.outstandingAmount}`
      };
    });
    const dataBank = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentType
      },
      {
        label: `${t('transaction_date')}:`,
        value: moment(new Date(props.paymentSummary?.transactionDate)).format('DD MMM YYYY')
      },
      {
        label: `${t('payment_value_date')}:`,
        value: moment(new Date(props.paymentSummary?.valueDate)).format('DD MMM YYYY')
      },
      {
        label: `${t('payment_time')}:`,
        value: moment(new Date()).format('DD MMM YYYY, H:mm A')
      }
    ];
    const dataCash = [
      {
        label: `${t('invoice_list')}:`,
        value: '',
        invoices
      },
      {
        label: `${t('payment_method')}:`,
        value: labelPaymentType
      },
      {
        label: `${t('deposit_date')}:`,
        value: moment(new Date(props.paymentSummary?.transactionDate)).format('DD MMM YYYY')
      },
      {
        label: `${t('payment_time')}:`,
        value: moment(new Date()).format('DD MMM YYYY, H:mm A')
      }
    ];
    const dataList = props.paymentType === INVOICE_PAYMENT_TYPE.BANK_TRANSFER ? dataBank : dataCash;
    return {
      dataList,
      label,
      labelNotes,
      labelDescTransaction,
      alertCircleOutline,
      labelPayment,
      labelBackInvoice,
      labelBackHome,
      currencySymbolData,
      totalBalanceUsed,
      totalPaidAmount,
      notes,
      appliedCreditsLabel,
      pending
    };
  }
});
</script>
<style lang="scss" scoped>
.f-icon {
  font-size: 3rem;
  color: #eb8c31;
}

.f-orange {
  color: #eb8c31;
}
ion-button {
  width: 95%;
  margin: 0 auto;
}
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
  width: 100%;
}
</style>
