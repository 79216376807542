<template>
  <div class="mt-4">
    <div class="mx-3">
      <div class="box mb-2">
        <ion-grid>
          <ion-row class="ion-align-items-center ion-justify-content-between">
            <ion-col>
              <ion-row>
                <ion-label class="ml-1 mt-1">{{ labelNotes }} </ion-label>
              </ion-row>
              <ion-row class="ion-align-items-center">
                <ion-input
                  :value="inputValue"
                  :placeholder="labelDescTransaction"
                  @ionInput="$emit('updateNotes', $event.target.value)"
                  name="note"
                ></ion-input>
                <ion-icon
                  @click="$emit('clearInput')"
                  v-if="inputValue !== ''"
                  size="medium"
                  :icon="closeCircleOutline"
                ></ion-icon>
              </ion-row>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</template>
<script>
import { closeCircleOutline } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'input-remarks',
  emits: ['updateNotes', 'clearInput'],
  props: ['inputValue'],
  setup() {
    const { t } = useI18n();
    const labelNotes = t('notes');
    const labelDescTransaction = t('enter_desc_transaction');
    return {
      closeCircleOutline,
      labelNotes,
      labelDescTransaction
    };
  }
});
</script>
<style lang="scss" scoped>
.box {
  border: 1px solid var(--ion-color-text-gray-300);
  background-color: var(--ion-color-text-gray-100);
  border-radius: 10px;
  min-height: 56px;
}
.f-icon {
  font-size: 26px;
  color: gray;
}
</style>
