<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="toolbar-header">
        <ion-icon
          @click="goBack"
          size="large"
          color="primary"
          slot="start"
          :icon="chevronBackOutline"
        ></ion-icon>
        <ion-label class="fw-600" @click="goBack">{{ labelNavbar }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <skeleton-payment-details v-if="loading"></skeleton-payment-details>
    <ion-content v-else>
      <div class="mx-3 mt-4 d-flex flex-column">
        <ion-label color="primary" class="fs-3 fw-500 pb-2">{{ labelHeader }}</ion-label>
      </div>
      <calender-picker
        :isBankTransfer="paymentType === 4"
        @dateTransaction="updateDateTransaction"
        @datePayment="updateDatePayment"
      />
      <drop-zone class="drop-area" @files-dropped="onImageChange">
        <ion-input
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <payment-proof
          @triggerBottomSheet="setBottomSheetUpload(true)"
          :data="imageProof"
          @deleteImage="deleteImage"
          @onTapFullSizeImg="onTapFullSizeImg"
        />
      </drop-zone>
      <input-remarks @updateNotes="updateNotes" @clearInput="clearInput" :inputValue="note" />
    </ion-content>
    <skeleton-bottom v-if="loading"></skeleton-bottom>
    <bottom-content v-else @handlePayInvoices="createPayment" :disabledPay="imageProof.length === 0" />
    <!-- //modals -->
    <ion-modal :is-open="isSummary" :backdropDismiss="true">
      <payment-summary
        @backToInvoice="backToInvoice"
        @backToHome="backToHome"
        :paymentSummary="paymentSummary"
        :currencySymbol="currencySymbol"
        :isBankTransfer="isBankTransfer"
        :paymentType="paymentType"
        :invoice="invoices"
      />
    </ion-modal>
    <!-- // bottom sheet of uploading image  -->
    <ion-modal
      :is-open="isBottomSheetUpload"
      :backdropDismiss="true"
      :initial-breakpoint="0.2"
      @didDismiss="setBottomSheetUpload(false)"
      class="bor-15"
    >
      <ion-content>
        <ion-input
          type="file"
          accept="image/*;capture=camera"
          @ionInput="onImageChange"
          ref="imageUpload"
          class="d-none"
        >
        </ion-input>
        <ion-list>
          <ion-item>
            <ion-label @click="triggerUpload" class="text-center" color="primary">{{
              $t('open_document')
            }}</ion-label>
          </ion-item>
          <ion-item v-if="isNative">
            <ion-label @click="triggerOpenCam" class="text-center" color="primary">{{
              $t('camera')
            }}</ion-label>
          </ion-item>
          <ion-button @click="setBottomSheetUpload(false)" fill="outline" expand="block" class="no-border">
            <ion-label class="text-capitalize fw-600" color="primary">{{ $t('cancel') }}</ion-label>
          </ion-button>
        </ion-list>
      </ion-content>
    </ion-modal>
    <!-- show full size image -->
    <ion-modal :is-open="isOpenFullImage" :backdropDismiss="false" @didDismiss="setOpenFullImage(false)">
      <ion-header>
        <ion-toolbar>
          <ion-icon
            @click="setOpenFullImage(false)"
            size="large"
            color="primary"
            slot="end"
            :icon="closeCircleOutline"
          ></ion-icon>
        </ion-toolbar>
      </ion-header>
      <div class="image-container">
        <img :src="imagePreview" alt="img-preview" />
      </div>
    </ion-modal>
    <ion-toast
      :is-open="isAmountNegative"
      mode="ios"
      color="danger"
      :message="$t('invalid_amount_negative')"
      :duration="2000"
      position="top"
      @didDismiss="isAmountNegative = false"
    >
    </ion-toast>
    <ion-toast
      :is-open="imgError"
      mode="ios"
      color="danger"
      :message="imgErrorMessage"
      :duration="2000"
      position="top"
      @didDismiss="imgError = false"
    >
    </ion-toast>
    <ion-toast
      :is-open="isInvalidDateFuture || isInvalidDatePast"
      mode="ios"
      color="danger"
      :message="
        isInvalidDateFuture
          ? `${$t('future_date_error')} ${todayDate}!`
          : `${$t('past_date_error')} ${todayDate}!`
      "
      :duration="2000"
      position="top"
      @didDismiss="(isInvalidDateFuture = false), (isInvalidDatePast = false)"
    >
    </ion-toast>
    <ion-loading :is-open="isLoading" :message="$t('please_wait')" cssClass="my-custom-class" />
  </ion-page>
</template>
<script>
// packages
import { fileToBase64 } from '@/modules/b2b/services/libs/image';
import { ACTIONS } from '@/modules/b2b/store/payment/actions';
import { INVOICE_PAYMENT_TYPE } from '@/modules/shared/constants/';
import Clevertap from '@/services/shared/helper/clevertap';
import { Camera, CameraResultType } from '@capacitor/camera';
import { Capacitor } from '@capacitor/core';
import { alertController, useBackButton } from '@ionic/vue';
import { chevronBackOutline, closeCircleOutline, helpCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import { createNamespacedHelpers } from 'vuex';

// components
import DropZone from '@/components/DropZone';
import {
  PaymentProof,
  SkeletonBottom,
  SkeletonPaymentDetails
} from '@/modules/shared/components/invoices-payment';
import { BottomContent, CalenderPicker, InputRemarks, PaymentSummary } from './components';

const { mapActions, mapGetters } = createNamespacedHelpers('b2b/payment');
export default defineComponent({
  name: 'payment-details-b2b',
  components: {
    CalenderPicker,
    InputRemarks,
    PaymentProof,
    BottomContent,
    PaymentSummary,
    SkeletonPaymentDetails,
    SkeletonBottom,
    DropZone
  },
  setup() {
    const isNative = Capacitor.isNativePlatform();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const paymentType = +route.query['payment-type'];
    const isBankTransfer = [INVOICE_PAYMENT_TYPE.BANK_TRANSFER].includes(paymentType)
      ? ref(true)
      : ref(false);
    const isSummary = ref(false);
    const isBottomSheetUpload = ref(false);
    const setBottomSheetUpload = (state) => (isBottomSheetUpload.value = state);
    const isLoading = ref(false);
    const labelOptions = {
      [INVOICE_PAYMENT_TYPE.BANK_TRANSFER]: t('bank_transfer_detail'),
      [INVOICE_PAYMENT_TYPE.CHEQUE]: t('cheque_detail'),
      [INVOICE_PAYMENT_TYPE.CASH_PAYMENT_COLLECTION]: t('cash_payment_collection_detail'),
      [INVOICE_PAYMENT_TYPE.CASH_DEPOSIT]: t('cash_deposit_detail')
    };
    const labelHeader = labelOptions[paymentType];
    const labelNavbar = t('payment_details');
    const labelAmount = t('total_amount');
    const imageUpload = ref({});
    const imageProof = ref([]);
    const isAmountNegative = ref(false);
    const note = ref('');
    const isInvalidDateFuture = ref(false);
    const isInvalidDatePast = ref(false);
    const todayDate = moment().format('DD-MM-YYYY');
    const imgError = ref(false);
    const imgErrorMessage = ref('');
    const goBack = () => {
      router.back();
    };
    const backToInvoice = () => {
      isSummary.value = false;
      router.replace('/b2b/invoices/select-invoices');
    };
    const backToHome = () => {
      isSummary.value = false;
      router.replace('/b2b/main/home');
    };
    const triggerUpload = async () => {
      const ionInputEl = imageUpload.value.$el;
      const inputEl = await ionInputEl.getInputElement();
      inputEl.click();
    };
    const onImageChange = async (e) => {
      const files = e.target ? e.target.querySelector('input').files : e;
      if (!files.length) return;
      if (!files[0].type.includes('image')) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_type');
        return;
      }
      if (files[0].size / 1024 > 5000) {
        imgError.value = true;
        imgErrorMessage.value = t('image_error_size');
        return;
      }
      const file = {
        imgName: files[0].name,
        imgSize: (files[0].size / 1024).toFixed(2) + ' KB',
        imgUrl: await fileToBase64(files[0]),
        imgBase64: await fileToBase64(files[0])
      };
      imageProof.value.push(file);
      setBottomSheetUpload(false);
    };
    const triggerOpenCam = async () => {
      await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Uri
      }).then(async (image) => {
        const fetchImgPath = await fetch(String(image.webPath));
        const blob = await fetchImgPath.blob();
        const imgBase64 = await fileToBase64(blob);
        const padding = imgBase64.endsWith('==') ? 2 : imgBase64.endsWith('=') ? 1 : 0;
        const base64Length = imgBase64.length;
        const sizeInBytes = (base64Length / 4) * 3 - padding;
        const sizeInKb = (sizeInBytes / 1024).toFixed(2);
        if (sizeInKb > 5000) {
          imgError.value = true;
          imgErrorMessage.value = t('image_error_size');
          return;
        }
        const file = {
          imgName: image.path.split('/').pop(),
          imgSize: sizeInKb + ' KB',
          imgUrl: await String(image.webPath),
          imgBase64
        };
        imageProof.value.push(file);
        setBottomSheetUpload(false);
      });
    };
    const deleteImage = (idx) => {
      imageProof.value.splice(idx, 1);
    };
    const presentAlertPayment = async () => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        mode: 'ios',
        header: t('payment_pending'),
        message: t('payment_pending_info'),
        buttons: [
          {
            text: t('OK'),
            handler: () => {
              isSummary.value = true;
              deleteImage();
              note.value = '';
            }
          }
        ]
      });
      return alert.present();
    };

    const presentAlertError = async (message) => {
      const alert = await alertController.create({
        backdropDismiss: false,
        cssClass: 'my-custom-class',
        mode: 'ios',
        header: t('payment_creation_failed'),
        message: t(message),
        buttons: [
          {
            text: t('OK'),
            handler: () => {
              backToInvoice();
            }
          }
        ]
      });
      return alert.present();
    };
    const imagePreview = ref(null);
    const isOpenFullImage = ref(false);
    const setOpenFullImage = (state) => (isOpenFullImage.value = state);
    const onTapFullSizeImg = (imgUrl) => {
      imagePreview.value = imgUrl;
      setOpenFullImage(true);
    };
    useBackButton(10, () => {
      setOpenFullImage(false);
    });
    return {
      labelHeader,
      labelNavbar,
      isSummary,
      labelAmount,
      goBack,
      presentAlertPayment,
      helpCircleOutline,
      chevronBackOutline,
      closeCircleOutline,
      backToInvoice,
      backToHome,
      triggerUpload,
      triggerOpenCam,
      onImageChange,
      imageUpload,
      imageProof,
      deleteImage,
      note,
      presentAlertError,
      isAmountNegative,
      isLoading,
      isInvalidDateFuture,
      isInvalidDatePast,
      todayDate,
      paymentType,
      isBankTransfer,
      imgError,
      imgErrorMessage,
      INVOICE_PAYMENT_TYPE,
      transactionDate: ref(''),
      paymentValueDate: ref(''),
      paymentProof: ref([]),
      creditAllocationNotes: ref(null),
      isNative,
      isBottomSheetUpload,
      setBottomSheetUpload,
      onTapFullSizeImg,
      isOpenFullImage,
      imagePreview,
      setOpenFullImage
    };
  },
  computed: {
    ...mapGetters(['paymentSummary', 'status', 'error', 'loading', 'selectedPaymentInvoices']),
    invoices() {
      return this.selectedPaymentInvoices.selectedInvoices;
    },
    totalInvoiceAmount() {
      return this.selectedPaymentInvoices.totalInvoiceAmount;
    },
    totalPaidAmount() {
      return this.selectedPaymentInvoices.totalPaidAmount;
    },
    maximumCreditAmount() {
      const credit = this.selectedPaymentInvoices?.availableCreditAmount;
      return credit > 0 ? credit : 0;
    },
    isCreditShown() {
      return this.selectedPaymentInvoices.availableCreditAmount > 0;
    },
    currencySymbol() {
      return this.selectedPaymentInvoices.selectedInvoices[0]?.currencySymbol;
    },
    customerCreditAmount() {
      return this.selectedPaymentInvoices?.customerCreditAllocation || 0;
    }
  },
  methods: {
    ...mapActions([ACTIONS.CREATE_PAYMENT, ACTIONS.CREATE_CASH_PAYMENT]),
    updateDateTransaction(date) {
      const tomorrow = moment().add(1, 'hours');
      const compareDate = moment(date).diff(tomorrow);
      if (compareDate > 0) {
        this.isInvalidDateFuture = true;
        return;
      }
      this.transactionDate = moment(date).format('YYYY-MM-DD');
    },
    updateDatePayment(date) {
      const today = moment().date();
      const isSameDate = moment(date).date() === today;
      const tomorrow = moment().add(1, 'hours');
      const compareDate = moment(date).diff(tomorrow);
      if (isSameDate) {
        this.isInvalidDatePast = false;
        this.paymentValueDate = moment(date).format('YYYY-MM-DD');
      } else {
        if (compareDate < 0 && !this.isBankTransfer) {
          this.isInvalidDatePast = true;
        } else {
          this.isInvalidDatePast = false;
          this.paymentValueDate = moment(date).format('YYYY-MM-DD');
        }
      }
    },
    async createPayment() {
      Clevertap.onUserPayInvoice({
        paymentType: this.paymentType,
        amountSubmitted: this.totalPaidAmount
      });
      if ([INVOICE_PAYMENT_TYPE.BANK_TRANSFER].includes(this.paymentType))
        await this.createPaymentBankTransfer();
      else await this.createPaymentCashDeposit();
      if (this.isError) return;
      if (this.error) this.presentAlertError(this.error.message);
      else this.presentAlertPayment();
    },
    async createPaymentBankTransfer() {
      this.isLoading = true;
      const imageProofData = this.imageProof.map((proof) => proof.imgBase64);
      if (!imageProofData.length) {
        this.presentAlertError('missing_payment_proof');
        return;
      }
      const paramsCreatePayment = {
        invoiceIDs: this.invoices.map((inv) => inv.id),
        transactionDate: this.transactionDate || moment().format('YYYY-MM-DD'),
        paymentProof: imageProofData,
        paymentTypeId: INVOICE_PAYMENT_TYPE.BANK_TRANSFER,
        referenceCode: ``,
        valueDate: this.paymentValueDate || moment().format('YYYY-MM-DD'),
        notes: this.note,
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_PAYMENT]({ paramsCreatePayment });
      this.isLoading = false;
    },
    async createPaymentCashDeposit() {
      this.isLoading = true;
      const imageProofData = this.imageProof.map((proof) => proof.imgBase64);
      if (!imageProofData.length) {
        this.presentAlertError('missing_payment_proof');
        return;
      }
      const paramsCreateCashChequePayment = {
        invoiceIDs: this.invoices.map((inv) => inv.id),
        depositDate: this.transactionDate || moment().format('YYYY-MM-DD'),
        paymentTypeId: INVOICE_PAYMENT_TYPE.CASH_DEPOSIT,
        paymentProof: imageProofData,
        referenceCode: ``,
        notes: this.note,
        totalPaidAmount: Number(this.totalPaidAmount.toFixed(2)),
        balanceAllocationAmount: this.customerCreditAmount
      };
      await this[ACTIONS.CREATE_CASH_PAYMENT]({ paramsCreateCashChequePayment });
      this.isLoading = false;
    },
    updateNotes(value) {
      this.note = value;
    },
    clearInput() {
      this.note = '';
    }
  }
});
</script>
<style lang="scss" scoped>
.f-price {
  font-weight: 700;
  font-size: 22px;
}
.bor-15 {
  --border-radius: 15px;
}
.no-border {
  --border-color: none;
  --border-width: 0px !important;
}
.i-help {
  color: #eb8c31;
  font-size: 22px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
